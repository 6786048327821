import React, { useEffect, useState } from 'react';
import OEDropdown, { IDropdownValues, defaultDropdownValues } from '../../../core/components/form/OEDropdown';
import OEMultiSelect, { defaultMultiSelectLocalization } from '../../../core/components/form/OEMultiSelect';
import OEToggleSwitch from '../../../core/components/form/OEToggleSwitch';
import { canDebugReport } from '../../../reporting/entities/Report';
import { IMicroStrategyFilter } from '../../entities/MicroStrategyFilters';
import { ReportNoFilterTextValue } from '../../entities/ReportFilter';

interface IComponentInfo {
    filter: IMicroStrategyFilter;
    setValue: (i: IMicroStrategyFilter) => void;
    reportId: string;
}

const Filter: React.FunctionComponent<IComponentInfo> = ({ filter, setValue, reportId }) => {
    const [values, setValues] = useState<IDropdownValues[]>([]);
    const [localValue, setLocalValue] = useState<string>(filter.value);
    const [valueCount, setValueCount] = useState<number>(0);
    const [debug] = useState<boolean>(canDebugReport());
    const [isSuppressed, setIsSuppressed] = useState(false);

    useEffect(() => {
        const d: IDropdownValues[] = [];
        if (filter && filter.values && filter.values.length > 0) {
            if (!filter.pageFilter && filter.noFilterText) {
                d.push({
                    ...defaultDropdownValues, id: ReportNoFilterTextValue, name: filter.noFilterText
                })
            }
            for (const c of filter.values) {
                d.push({ ...defaultDropdownValues, id: c.id, name: c.name })
            }
            setValues([...d]);
            if (JSON.stringify(d) !== JSON.stringify(values)) {
                if (filter.supportMultiple) {
                    const v: string[] = filter.value ? filter.value.split(',') : [];
                    let s: string = '';
                    for (const c of filter.values) {
                        if (filter.value === '' || valueCount !== filter.values.length || v.length === 0 || v.find(q => q.toLowerCase().trim() === c.id.toLowerCase())) {
                            s = `${s}${s.length > 0 ? ',' : ''}${c.id}`;
                        }
                    }
                    updateValue(s);
                    setLocalValue(s);
                }
                else {
                    let match = d.find(q => q.id === filter.selection)
                    if (!match) {
                        match = d.find(q => q.name === filter.selection)
                    }
                    if (match && filter.selection !== '') {
                        updateValue(match.id);
                        setLocalValue(match.id);
                    }
                    else if (d.length > 0 && d.filter(q => q.id === filter.value).length === 0) {
                        updateValue(d[0].id);
                        setLocalValue(d[0].id);
                    }
                }
            }
            if (d.length === 0) {
                console.log('Filters have no values');
            }
            setValueCount(d.length);
        }
        // eslint-disable-next-line
    }, [filter.values]);

    const updateValue = (i: string) => {
        if (i !== filter.value) {
            setValue({ ...filter, value: i });
        }
    }

    const onToggleSuppression = () => {
        console.log('SUPPRESSION TOGGLED');
        setIsSuppressed(!isSuppressed);
        setValue({ ...filter, value: isSuppressed ? '0' : '1' });
    }

    return (
        <>
            {filter && (
                <>
                    {filter.isSuppression && (
                        <div className="pull-right">
                            <OEToggleSwitch text="Suppression" name={`${reportId}-${filter.name}`} onChange={onToggleSuppression} value={isSuppressed} />
                        </div>
                    )}
                    {!filter.isSuppression && (debug || (filter.values && filter.values.length > 0)) && (
                        <div className="filter">
                            {filter.label}
                            <br />
                            {filter.values && filter.values.length > 0 && (
                                <>
                                    {filter.supportMultiple && (
                                        <OEMultiSelect
                                            value={localValue}
                                            className="multi-select"
                                            values={filter.values}
                                            onChange={updateValue}
                                            localization={{
                                                ...defaultMultiSelectLocalization,
                                                selectAll: filter.selectAllText || defaultMultiSelectLocalization.selectAll
                                            }}
                                        />
                                    )}
                                    {!filter.supportMultiple && (
                                        <OEDropdown
                                            onChange={updateValue}
                                            value={filter.value}
                                            values={values} />
                                    )}
                                </>
                            )}

                            {debug && (!filter.values || filter.values.length === 0) && (
                                <i>No Values Loaded</i>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default Filter;
