/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { IDropdownValues } from '../../../core/components/form/OEDropdown';
import OEMessage from '../../../core/components/messaging/OEMessage';
import OESpinner, { SpinnerStyle } from '../../../core/components/messaging/OESpinner';
import { INotification } from '../../../core/components/messaging/entities/Notification';
import { MessageType } from '../../../core/components/messaging/enums/InformationMessages';
import { emptyGUID } from '../../../core/utilities/String';
import { refreshPage } from '../../../core/utilities/URL';
import { IReport, canDebugReport } from '../../../reporting/entities/Report';
import { IReportPage } from '../../../reporting/entities/ReportPage';
import { ProcessingPage, ProcessingPageFilters, getProcessingPageFiltersLabel, logProcessingPageFilters } from '../../../reporting/entities/ReportProcessing';
import { ReportType } from '../../../reporting/entities/ReportType';
import { ISiteReport, defaultSiteReport } from '../../../reporting/entities/SiteReport';
import { useGetEmbeddedReportFilters } from '../../../reporting/services/ReportFilterService';
import { useGetSiteReport, useGetSiteReportFilters } from '../../../reporting/services/SiteReportService';
import { IMicroStrategyFilter } from '../../entities/MicroStrategyFilters';
import { MicroStrategyDossierFilterType, MicroStrategySingleSelectAllValue } from '../../entities/MicrostrategyDossierFilter';
import { IReportFilter, ReportNoFilterTextValue, getFiltersFromReportFilters, getReportFilterFromJSON, getReportFilterList } from '../../entities/ReportFilter';
import { CubeAttributeElementResponseSeparator, ICubeAttributeElementResponse } from '../../entities/api/CubeAttributeElementResponse';
import { ICubeAttribute } from '../../entities/api/CubeResponse';
import Filters from './Filters';

interface IPageFiltersInfo {
    report: IReport;
    page?: IReportPage;
    cubeAttributes: ICubeAttribute[];
    setNotification: (n: INotification) => void;
    pageProcessingStep: ProcessingPage;
    setPageProcessingStep: (i: ProcessingPage) => void;
    setPageFilters: (i: IMicroStrategyFilter[]) => void;
    setDebugMessage?: (i: string) => void;
}

const PageFilters: React.FunctionComponent<IPageFiltersInfo> = ({
    report, page, setNotification, setPageProcessingStep, setPageFilters, pageProcessingStep, cubeAttributes, setDebugMessage
}) => {
    const { service: embeddedFilterService, doRefresh: loadBaseFilters } = useGetEmbeddedReportFilters(false); // Step 1 - get base filters for all objects
    const { service: siteReportService, setId: setSiteReportId } = useGetSiteReport(''); // Step 2 - get the site report inormation
    const { service: siteReportFilterService, setReport: setSiteReportFilter } = useGetSiteReportFilters(); // Step 3 - get filters for page

    const [processingStep, setProcessingStep] = useState<ProcessingPageFilters>(ProcessingPageFilters.Idle);
    const [siteReport, setSiteReport] = useState<ISiteReport>(defaultSiteReport);

    const [siteFilters, setSiteFilters] = useState<IReportFilter[]>([]);
    const [baseFilters, setBaseFilters] = useState<IReportFilter[]>([]);
    const [filters, setFilters] = useState<IMicroStrategyFilter[]>([]);
    const [reportFilters, setReportFilters] = useState<IReportFilter[]>([]);

    const [debug] = useState<boolean>(canDebugReport);
    const [message, setMessage] = useState<string>('Loading Page Filters');
    const [error, setError] = useState<string>('');

    useEffect(() => {
        switch (pageProcessingStep) {
            case ProcessingPage.FatalError:
                setProcessingStep(ProcessingPageFilters.FatalError);
                setMessage('');
                break;
        }
        // eslint-disable-next-line
    }, [pageProcessingStep]);

    useEffect(() => {
        logProcessingPageFilters(processingStep, debug);
        setDebugMessage?.(getProcessingPageFiltersLabel(processingStep));
        if (pageProcessingStep === ProcessingPage.FatalError) {
            setProcessingStep(ProcessingPageFilters.FatalError);
            setMessage('');
            return;
        }
        switch (processingStep) {
            case ProcessingPageFilters.Start:
                if (baseFilters.length === 0) {
                    loadBaseFilters();
                }
                else {
                    setProcessingStep(ProcessingPageFilters.SiteReportLoad);
                }
                break;

            case ProcessingPageFilters.SiteReportLoad:
                if (emptyGUID(siteReport.id)) {
                    setSiteReportId(ReportType.EmbeddedFilters);
                }
                else {
                    setProcessingStep(ProcessingPageFilters.CreatePageFilters);
                }
                break;

            case ProcessingPageFilters.CreatePageFilters:
                setProcessingStep(ProcessingPageFilters.SiteReportFiltersLoad);
                break;

            case ProcessingPageFilters.SiteReportFiltersLoad:
                setMessage('Loading Page Filters');

                let f: IReportFilter[] = [];
                if (reportFilters.length > 0) {
                    f = reportFilters;
                }
                else {
                    f = getReportFilterFromJSON(page ? page.filters : report.filters);
                }
                for (const c of f) {
                    c.filterLoaded = false;
                }
                setReportFilters(f.filter(q => !q.dossier));
                setSiteReportFilter({
                    ...siteReport,
                    pageId: page?.id,
                    reportFilters: getFiltersFromReportFilters(f.filter(q => !q.dossier), baseFilters)
                });
                break;

            case ProcessingPageFilters.SiteReportFiltersComplete:
                for (const i of siteFilters) {
                    let match = reportFilters.find(q => q.name.toLowerCase() === i.name.toLowerCase());
                    if (match) {
                        match.values = i.values;
                        match.value = i.selectedValue;
                    }
                }
                matchFilters();
                break;

            case ProcessingPageFilters.UpdateFilters:
                const d: IMicroStrategyFilter[] = [];
                for (const c of reportFilters) {
                    let s: string = '';
                    if (c.values) {
                        let f = c.values.filter(q => q.id === c.value);
                        if (f && f.length > 0) {
                            s = f[0].parentId;
                        }
                    }
                    d.push({
                        key: c.msId,
                        selection: s,
                        name: c.msName,
                        externalId: c.externalId,
                        pageFilter: true,
                        values: c.values,
                        value: c.value,
                        label: c.label,
                        cascadePriority: c.cascadePriority,
                        sortOrder: c.sortOrder,
                        displayValue: c.value,
                        rangeIncrements: c.rangeIncrements || "2",
                        isSuppression: false,
                        filterType: c.filterType,
                        defaultSelection: c.defaultSelection || ''
                    });
                }
                setPageFilters(d);
                setFilters(d);
                setProcessingStep(ProcessingPageFilters.Complete);
                break;

            case ProcessingPageFilters.Complete:
                setPageProcessingStep(pageProcessingStep === ProcessingPage.PageFiltersLoad ? ProcessingPage.PageFiltersLoaded : ProcessingPage.PageFiltersUpdated);
                setMessage('');
                break;

            case ProcessingPageFilters.FatalError:
                setMessage('')
                setPageFilters([]);
                setPageProcessingStep(ProcessingPage.FatalError);
                break;
        }
        // eslint-disable-next-line
    }, [processingStep]);

    useEffect(() => {
        if (cubeAttributes.length > 0) {
            setProcessingStep(ProcessingPageFilters.Start);
        }
        // eslint-disable-next-line
    }, [cubeAttributes]);

    useEffect(() => {
        if (embeddedFilterService.result) {
            setBaseFilters(getReportFilterList(embeddedFilterService.result));
        }
        // eslint-disable-next-line
    }, [embeddedFilterService]);

    useEffect(() => {
        if (baseFilters.length > 0) {
            setProcessingStep(ProcessingPageFilters.SiteReportLoad);
        }
        // eslint-disable-next-line
    }, [baseFilters]);

    useEffect(() => {
        if (siteReportService.result) {
            setSiteReport(siteReportService.result);
        }
        // eslint-disable-next-line
    }, [siteReportService]);

    useEffect(() => {
        if (!emptyGUID(siteReport.id) && pageProcessingStep !== ProcessingPage.FatalError) {
            setProcessingStep(ProcessingPageFilters.CreatePageFilters);
        }
        // eslint-disable-next-line
    }, [siteReport]);

    useEffect(() => {
        if (siteReportFilterService.isFinished) {
            if (siteReportFilterService.data) {
                setSiteFilters(siteReportFilterService.data.data);
            }
            else {
                setNotification({ message: `Re-loading Site Filters (PageFilters)`, type: 'error' })
                refreshPage();
            }
        }
        // eslint-disable-next-line
    }, [siteReportFilterService]);

    useEffect(() => {
        switch (processingStep) {
            case ProcessingPageFilters.SiteReportFiltersLoad:
                setProcessingStep(ProcessingPageFilters.SiteReportFiltersComplete);
                break;
        }
        // eslint-disable-next-line
    }, [siteFilters]);

    useEffect(() => {
        switch (processingStep) {
            case ProcessingPageFilters.SiteReportFiltersComplete:
                setProcessingStep(ProcessingPageFilters.UpdateFilters);
                break;
        }
        // eslint-disable-next-line
    }, [reportFilters]);

    const matchFilters = () => {
        for (const r of reportFilters) {
            const i: string[] = r.externalId.split(',');
            for (const s of i) {
                let d = cubeAttributes.find(q => q.name === s);
                if (d) {
                    r.msId = d.id;
                    for (const t of r.values) {
                        t.parentId = setPageFilterKeys(r, t, d.elements);
                    }
                }
            }
            if (r.filterType === MicroStrategyDossierFilterType.SingleSelectWithAll) {
                r.values.unshift({ id: MicroStrategySingleSelectAllValue, name: r.selectAllLabel, parentId: MicroStrategySingleSelectAllValue });
            }
        }
        setReportFilters([...reportFilters]);
    }

    const setPageFilterKeys = (r: IReportFilter, v: IDropdownValues, elements: ICubeAttributeElementResponse[]): string => {
        for (const e of elements) {
            if (r.value === ReportNoFilterTextValue && r.dossier) {
                return r.value;
            }
            try {

                let matchValue: string = '';
                let rawValue: string = e.rawValue;

                // Schools and District have multiple raw values so we must match on id-name values
                for (const rv of rawValue.split(CubeAttributeElementResponseSeparator)) {
                    matchValue = `${v.id.replace(/-/g, '')}-${v.name.toLowerCase()}`.replace(/^0+/, '');
                    if (rv.toLowerCase() === matchValue.toLowerCase()) {
                        return e.key;
                    }
                }

                // match on exact value with report filter label
                if (e.value.toLowerCase().startsWith(v.name)) {
                    return e.key;
                }

                // match on exact value with report filter value
                if (e.value.toLowerCase().startsWith(v.id)) {
                    return e.key;
                }

                // match on exact value with ending ';'
                matchValue = v.id.toLowerCase() + ';'
                if (e.key.toLowerCase().startsWith(matchValue) || e.id.toLowerCase().startsWith(matchValue)) {
                    return e.key;
                }

            }
            catch {
                return '';
            }
        }
        return '';
    }

    const updateFilterValues = (f: IMicroStrategyFilter) => {
        const t: IReportFilter = reportFilters.filter(q => q.msId === f.key)[0]
        t.value = f.value;
        t.valueName = f.values.filter(q => q.id === f.value)[0].name;
        setProcessingStep(ProcessingPageFilters.SiteReportFiltersLoad);
    }

    return (
        <>
            <Filters
                updateFilterValues={updateFilterValues}
                filters={filters}
                reportId={report.id}
            />
            <OESpinner message={message} oeStyle={SpinnerStyle.Small} />
            {processingStep === ProcessingPageFilters.FatalError && (
                <OEMessage
                    className="report-errors"
                    hideDismissable={true}
                    message={error}
                    type={MessageType.Error}
                />
            )}

        </>
    );
};

export default PageFilters;
