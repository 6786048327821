import React, { useState } from 'react';
import { ISiteMenu } from '../../entities/SiteMenu';
import '../../styles/SiteMenu.css';
import { saveRootMenu } from '../SiteMenu';

interface IComponentInfo {
    item: ISiteMenu;
    isActive?: boolean;
}

const Menu: React.FunctionComponent<IComponentInfo> = ({ item, isActive, }) => {
    const [isOpen, setIsOpen] = useState(false);

    const onClick = () => {
        saveRootMenu(item);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === ' ') {
            setIsOpen(!isOpen);
        }
    };

    const isSubmenuClass = item.children.length > 0 ? (item.parentId > 0 ? 'subchildmenu' : 'childmenu') : '';

    return (
        <li>
            <a
                href={item.children.length > 0 ? undefined : item.path}
                className={`nav-link link-light px-3 ${isActive ? 'active' : ''} ${isSubmenuClass}`}
                aria-current="page"
                aria-haspopup={item.children.length > 0 ? "true" : undefined}
                aria-expanded={item.children.length > 0 && isOpen ? "true" : "false"}
                target={item.target || '_self'}
                onClick={onClick}
                onKeyDown={handleKeyDown}
                tabIndex={0}
            >
                <i className={item.icon} title={item.name}></i> {item.name}
            </a>

            {item.children.length > 0 && (
                <ul className={isOpen ? 'open' : ''} role="menu">
                    {item.children.map((menu) => (
                        <Menu item={menu} isActive={false} key={menu.id} />
                    ))}
                </ul>
            )}
        </li>

    );
};

export default Menu;
