import React, { useEffect, useState } from 'react';
import { OECol } from '../../../core/components/grid/OECol';
import { OERow } from '../../../core/components/grid/OERow';
import OETable from '../../../core/components/table/OETable';
import { ColumnType, IColumn } from '../../../core/components/table/entities/Column';
import { IColumnAction } from '../../../core/components/table/entities/ColumnAction';
import { Icon } from '../../../core/entities/Icon';
import { IReportConfigurationItem, IReportConfigurationItemForSave, ReportConfigurationDataType, ReportConfigurationType, defaultReportConfigurationItem, getJSONForReportConfigurationSave, getJSONFromReportConfiguration, getReportConfigurationDisplayValue } from '../../entities/ReportConfiguration';
import { ReportIconBaseURL } from '../../entities/ReportIcons';
import ConfigurationFormik from './ConfigurationFormik';

enum ModalTypes {
    None = 1,
    Edit,
    Add,
}

interface IComponentInfo {
    label?: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    onUpdated?: () => void;
    values: any;
    configurations: IReportConfigurationItem[];
    hasIcons?: boolean;
}

const Configuration: React.FunctionComponent<IComponentInfo> = ({ label, setFieldValue, onUpdated, values, configurations, hasIcons }) => {
    const [showModal, setShowModal] = useState<ModalTypes>(ModalTypes.None);
    const [items, setItems] = useState<IReportConfigurationItem[]>(configurations);
    const [item, setItem] = useState<IReportConfigurationItem>(defaultReportConfigurationItem);

    useEffect(() => {
        const i: IReportConfigurationItemForSave[] = getJSONFromReportConfiguration(values.configuration);
        if (i.length > 0) {
            for (const c of items) {
                if (i.filter(q => q.id === c.id).length > 0) {
                    const p: IReportConfigurationItemForSave = i.filter(q => q.id === c.id)[0];
                    c.isSet = true;
                    c.value = p.value;
                    c.displayValue = getReportConfigurationDisplayValue(c);
                }
                else {
                    c.isSet = false;
                }
            }
        }
        setItems([...items]);
        // eslint-disable-next-line
    }, [values.configuration]);

    const onAdd = () => {
        setShowModal(ModalTypes.Add);
        setItem(defaultReportConfigurationItem);
    };

    const onCancel = () => {
        setShowModal(ModalTypes.None);
    };

    const onDeleteParameter = (i: IReportConfigurationItem) => {
        items.filter(q => q.id === i.id)[0].isSet = false;
        setFieldValue("configuration", getJSONForReportConfigurationSave(items.filter(q => q.isSet)));
        onUpdated && onUpdated();
    }

    const onSubmit = (i: IReportConfigurationItem) => {
        const c: IReportConfigurationItem = items.filter(q => q.id === i.id)[0];
        c.isSet = true;
        c.value = i.value;
        setFieldValue("configuration", getJSONForReportConfigurationSave(items.filter(q => q.isSet)));
        onUpdated && onUpdated();
        setShowModal(ModalTypes.None);
    };

    const onEditParameter = (i: IReportConfigurationItem) => {
        setShowModal(ModalTypes.Edit);
        setItem(i);
    };

    const actions: IColumnAction[] = [
        { icon: Icon.Edit, onClick: onEditParameter, helpText: 'Edit' },
        { icon: Icon.Delete, onClick: onDeleteParameter, helpText: 'Delete', condition: 'disableDelete', notCondition: true },
    ];

    const columns: IColumn[] = [
        { actions, id: '', name: '', sort: false, type: ColumnType.Actions, width: '20px' },
        { id: 'name', name: 'Type', sort: false, type: ColumnType.String },
        { id: 'displayValue', name: 'Value', sort: false, type: ColumnType.String, displayHTML: true },
    ];

    const iconColumns: IColumn[] = [
        { actions, id: '', name: '', sort: false, type: ColumnType.Actions, width: '20px' },
        { id: 'name', name: 'Type', sort: false, type: ColumnType.String },
        { id: 'value', className: "report-icon text-center", baseURL: ReportIconBaseURL, name: 'Icon', sort: false, type: ColumnType.Image },
    ];

    return (
        <>
            {items.length > 0 && (
                <>
                    <OERow>
                        {configurations.filter(q => q.id === ReportConfigurationType.Icon || q.id === ReportConfigurationType.IconOff).length > 0 && (
                            <>
                                <OECol sm={2}>{label || 'Configuration (Icon)'}</OECol>
                                <OECol sm={10}>
                                    <OETable
                                        columns={iconColumns}
                                        data={items.filter(q => q.isSet && q.dataType === ReportConfigurationDataType.Icon)}
                                        className="table-bordered m-t-5"
                                        noDataMessage="No Configuration icons have been assigned"
                                    />
                                </OECol>
                            </>
                        )}
                        <OECol sm={2}>{label || 'Configuration'}</OECol>
                        <OECol sm={10}>
                            <OETable
                                columns={columns}
                                data={items.filter(q => q.isSet && q.dataType !== ReportConfigurationDataType.Icon)}
                                className="table-bordered m-t-5"
                                noDataMessage="No Configuration values have been assigned"
                                actions={[
                                    { icon: Icon.Add, text: 'Add New Configuration Item', action: onAdd },
                                ]}
                            />
                        </OECol>
                    </OERow>
                </>
            )}
            {showModal !== ModalTypes.None && (
                <ConfigurationFormik
                    onCancel={onCancel}
                    configuration={items}
                    isEditing={showModal === ModalTypes.Edit}
                    onSubmit={onSubmit}
                    item={item}
                />
            )}
        </>
    );
};

export default Configuration;
