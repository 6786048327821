import React, { useState } from 'react';
import OEIcon from '../../../core/components/general/OEIcon';
import { Icon } from '../../../core/entities/Icon';
import ReportDescription from '../../../reporting/components/common/ReportDescription';
import ReportFlagging from '../../../reporting/components/report-flag/ReportFlagging';
import { IReport } from '../../../reporting/entities/Report';
import { IMicroStrategyFilter } from '../../entities/MicroStrategyFilters';

interface IReportTitle {
    report: IReport;
    flagging: boolean;
    pageFilters: IMicroStrategyFilter[];
}

const ReportTitle: React.FunctionComponent<IReportTitle> = ({ report, flagging, pageFilters }) => {
    const [showDescription, setShowDescription] = useState<boolean>(false);

    const onShowDescription = () => {
        setShowDescription(true);
    }
    const onHideDescription = () => {
        setShowDescription(false);
    }

    return (
        <>
            <p className='title-style'>{report.title}</p>
            {report.description && report.description.length > 0 && (
                <OEIcon
                    onClick={onShowDescription}
                    className=" report-description-link m-l-5"
                    icon={Icon.ReportDescriptionLink}
                />
            )}

            {flagging && (
                <ReportFlagging report={report} pageFilters={pageFilters} />
            )}

            {showDescription && (
                <ReportDescription title={report.title} description={report.description} onCancel={onHideDescription} />
            )}
        </>
    );
}



export default ReportTitle;
