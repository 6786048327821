import React, { useEffect, useRef, useState } from 'react';
import OEHeading from '../../../core/components/general/OEHeading';
import { Icon } from '../../../core/entities/Icon';
import { OERow } from '../../../core/components/grid/OERow';
import { OECol } from '../../../core/components/grid/OECol';
import { getGlossaryGroupList, useGetGlossaryTermGroup } from '../../services/GlossaryService';
import { IGlossary, IGlossaryGroup } from '../../entities/Glossary';
import OEIcon from '../../../core/components/general/OEIcon';
import { OEFormGroup } from '../../../core/components/form/OEForm';
import OEInput from '../../../core/components/form/OEInput';

const Glossary: React.FunctionComponent = () => {

    const { service } = useGetGlossaryTermGroup();

    const elementRef = useRef<any>();

    const [isVisible, setIsVisible] = useState(false);
    const [items, setItems] = useState<IGlossaryGroup[]>([]);
    const [search, setSearch] = useState("")
    const [searchList, setSearchList] = useState<IGlossary[]>([])

    useEffect(() => {
        const handleScroll = () => {
            if (elementRef.current) {
                const rect = elementRef.current.getBoundingClientRect();
                const isVisible = (
                    rect.top >= 0 &&
                    rect.y >= 0
                );
                setIsVisible(!isVisible);
            }
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        if (service.result) {
            setItems(getGlossaryGroupList(service.result));
        }
        // eslint-disable-next-line
    }, [service]);

    const scrollToLetter = (letter: string) => {
        const element = document.getElementById(letter);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div ref={elementRef} id={"Glossary"} className="container">
            <OEHeading className="m-b-20 m-t-10" text="Glossary" icon={Icon.ReportAdministration} />
            <OERow className="">
                {items.map(letter => (
                    <OECol className='glossary-link-container' key={letter.name}>
                        <button className='glossary-link' onClick={() => scrollToLetter(letter.name)}>
                            {letter.name}
                        </button>
                    </OECol>
                ))}
                <OECol className="">
                    <OEFormGroup inline={true} className="m-r-10">
                        <OEInput
                            className='glossary-search'
                            onChange={(e) => {
                                setSearch(e);
                                if (e.length > 2) {
                                    let objArray: IGlossary[] = [];
                                    items.forEach((glossary, i) => {
                                        let data = glossary.items.filter(item => item.term.toUpperCase().includes(e.toUpperCase()));
                                        if (data.length) {
                                            objArray = [...objArray, ...data];
                                        }
                                    });
                                    setSearchList(objArray);
                                } else {
                                    setSearchList([]);
                                }
                            }}
                            name="type"
                            placeholder='Glossary Search'
                            value={search} />

                        {search.length !== 0 && search.length < 3 &&
                            <div className='suggestion-box'>
                                <p>Enter at least three characters</p>
                            </div>}

                        {searchList.length ? <div style={{ maxHeight: '500', overflowY: 'auto' }}>
                            {searchList.map((item, index) =>
                                <div onClick={() => {
                                    setSearch("");
                                    setSearchList([]);
                                    scrollToLetter(item.term);
                                }}
                                    style={{ backgroundColor: 'white', position: 'relative', maxHeight: 80, padding: 5, borderBottomWidth: 2, borderBottomColor: '#dee2e6' }}>
                                    <p style={{ fontSize: 14, fontWeight: 400, }}>
                                        {item.term}</p>
                                    <p style={{ fontSize: 12, fontWeight: 300 }}>{item.definition}</p>
                                </div>)}
                        </div> : null}
                    </OEFormGroup>
                </OECol>
            </OERow>
            {items.map(letter => (
                <OERow className="py-4" key={letter.name} id={letter.name}>
                    <OECol style={{ flex: .1 }} className="">
                        <h2 className='glossary-group'>{letter.name}</h2>
                    </OECol>
                    <OECol style={{ flex: 2 }} className=""> {letter.items.map((glossary, i) =>
                        <div className='glossary-item' id={glossary.term}>
                            <h4 className='glossary-term'>{glossary.term}</h4>
                            <p className='glossary-definition'>{glossary.definition}</p>
                        </div>
                    )}
                    </OECol>
                </OERow>
            ))}
            {isVisible &&
                <div className='bottom-right'>
                    <OEIcon icon={Icon.CaretUp} onClick={() => scrollToLetter("Glossary")} />
                </div>
            }
        </div>
    );
};

export default Glossary;