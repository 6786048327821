interface Chapter {
    key: string
    name: string
    pages: Page[]
    filters: IDossierDefinitionFilter[]
}

interface Page {
    key: string
    name: string
    visualizations: Visualization[]
    fields: Field[]
}

interface Visualization {
    key: string
    name: string
}

interface Field {
    key: string
    name: string
    fieldType: string
}

export interface IDossierDefinitionFilter {
    key: string
    name: string
    summary: string
    source: Source
}

interface Source {
    id: string
    type: number
}

export interface IDossierDefinition {
    id: string
    name: string
    chapters: Chapter[]
}

export const getDefaultDossierDefinition = (): IDossierDefinition => JSON.parse(JSON.stringify({
    id: '', name: '', chapters: []
}));

