import { ICubeAttributeElementResponse } from "./CubeAttributeElementResponse";

export interface ICubeAttributeElements {
    id: string;
    elements: ICubeAttributeElementResponse[];
}

export const getDefaultCubeAttributeElements = (): ICubeAttributeElements => JSON.parse(JSON.stringify({
    id: '', elements: []
}));

export interface ICubeAttribute {
    id: string;
    name: string;
    type: string;
    cubeId: string;
    elements: ICubeAttributeElementResponse[];
    loaded: boolean;
}

export const getDefaultCubeAttribute = (): ICubeAttribute => JSON.parse(JSON.stringify({
    id: '', name: '', type: '', elements: [], cubeId: '', loading: false
}));

interface ICubeAttributes {
    attributes: ICubeAttribute[];
}

const defaultAvailableObjects: ICubeAttributes = {
    attributes: []
};

interface IDefinition {
    availableObjects: ICubeAttributes;
}

const defaultDefinition: IDefinition = {
    availableObjects: defaultAvailableObjects
};

interface IResult {
    definition: IDefinition;
}

const defaultResult: IResult = {
    definition: defaultDefinition

};

export interface ICubeResponse {
    id: string;
    name: string;
    result: IResult;
}

export const defaultCubeResponse: ICubeResponse = {
    id: '', name: '', result: defaultResult
}


