import { IOEParameter } from '../../core/components/parameters/entities/OEParameter';
import { IBaseAuditedEntity } from '../../core/entities/BaseAuditedEntity';
import { IBaseList } from "../../core/entities/BaseList";
import { canDebug } from '../../core/utilities/Miscellaneous';
import { parseQueryString } from '../../core/utilities/URL';
import { defaultCubeRequest, ICubeRequest } from '../../report-microstrategy/entities/api/CubeRequest';
import { getJSONFromReportConfiguration, getReportConfigurationValue, IReportConfigurationItemForSave, ReportConfigurationType } from './ReportConfiguration';
import { defaultReportServer, IReportServer } from './ReportServer';
import { defaultReportType, IReportType, isPowerBIReport, isSiteReport } from './ReportType';
import { defaultSession, ISession } from './Session';

export enum ReportFilterLocation {
    Page = 'P',
    Section = 'S',
    Group = 'G',
    Report = 'R',
}

export interface IReport extends IBaseAuditedEntity {
    id: string;
    title: string;
    description: string;
    externalId: string;
    parameters: string;
    width: number;
    height: number;
    storedProcedure: string;
    filterProcedure: string;
    reportTypeId: string;
    reportTypeName?: string;
    isActive: boolean;
    sortOrder: number;
    configuration: string;
    filters: string;
    path: string;
    reportType?: IReportType;
}

export const defaultReport: IReport = {
    id: '', title: '', description: '', externalId: '', parameters: '', width: 0, height: 0,
    storedProcedure: '', filterProcedure: '', reportTypeId: '', isActive: true, sortOrder: 0,
    configuration: '', path: '', filters: ''
};

export interface IReportListItem extends IBaseAuditedEntity {
    report: IReport;
    reportType: IReportType;
    reportServer: IReportServer;
    reportParameters: IOEParameter[];
    session: ISession;
}

export const defaultReportListItem: IReportListItem = {
    report: defaultReport, reportType: defaultReportType, reportServer: defaultReportServer,
    reportParameters: [], session: defaultSession
};

export const getReportList = (i: IBaseList<IReportListItem>): IReport[] => {
    const d: IReport[] = [];
    for (const v of i.items) {
        d.push({
            ...defaultReport,
            ...v.report,
            configuration: v.report.configuration,
            path: getReportPath(v.report.configuration, v.report.id, v.reportType.id),
            reportType: v.reportType,
            reportTypeName: v.reportType ? v.reportType.name : '',
        });
    }
    return d;
};

export const getReportPath = (config: string, id: string, type: string): string => {

    if (isSiteReport(type)) {
        return `report?sitereports=${id}`;
    }

    if (isPowerBIReport(type)) {
        return `powerbi?id=${id}`;
    }

    const i: IReportConfigurationItemForSave[] = getJSONFromReportConfiguration(config).filter(q => q.id === ReportConfigurationType.ReportType);

    if (i.length > 0 && i[0].value === 'RF') {
        return `/iframe?id=${id}`;
    }

    else {
        return `/embedded?id=${id}`;
    }
}

export const canDebugReport = (): boolean => {
    const params = parseQueryString();
    try {
        return params.debug === '1' && canDebug()
    }
    catch {
        return false;
    }
}

export const getReportCubeRequest = (i: IReport): ICubeRequest => {
    const t: ICubeRequest = defaultCubeRequest;
    try {
        t.id = getReportConfigurationValue(ReportConfigurationType.CubeID, i.configuration);
        t.projectId = i.externalId.split('/')[0];
        t.dossierId = i.externalId.split('/')[1];
        t.reportId = i.id;
    }
    catch {
        t.id = 'ERROR';
    }
    return t;
}

