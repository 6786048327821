import React, { Children, useEffect, useState } from 'react';
import ChatBtn from '../../chat/components/ChatBtn';
import ChatWidget from '../../chat/components/ChatWidget';
import { redirectLogin } from '../../core/services/Authentication';
import { ISiteSettings } from '../entities/SiteSettings';
import { SessionController } from './SessionController';
import SiteFooter from './SiteFooter';
import SiteMenu from './SiteMenu';

export interface ISessionTimer {
    seconds: number;
    isActive: boolean;
    initialized: boolean;
    initializing: boolean;
}


interface IComponentInfo {
    settings: ISiteSettings;
    children?: any;
}

const Layout: React.FunctionComponent<IComponentInfo> = ({ children, settings }) => {
    const [maintenance, setMaintenance] = useState<boolean>(false);
    const [logout, setLogout] = useState<boolean>(false);

    const [showChat, setShowChat] = useState(false);

    useEffect(() => {
        if (typeof settings === 'string') {
            redirectLogin();
        }
        else {
            try {
                localStorage.setItem('reportShowDelay', settings.reportShowDelay.toString());
                localStorage.setItem('msPath', settings.msPath);
                localStorage.setItem('msauthurl', settings.msAuthenticationURL);
                localStorage.setItem('debug-setting', settings.canDebug ? '1' : '0');
                localStorage.setItem('servertimezone', settings.serverTimeZone);
                setMaintenance(settings.maintenance && !settings.canDebug);

                if (settings.maintenance && !settings.canDebug && !window.location.pathname.includes('maintenance')) {
                    window.location.href = "/maintenance";
                }
                else if (!settings.maintenance && window.location.pathname.includes('maintenance')) {
                    window.location.href = "/home";
                }
            }
            catch {
            }
        }
        // eslint-disable-next-line
    }, [settings]);


    const onLogout = () => {
        setLogout(true);
    }

    const handleSkipToContent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();

        const ndBody = document.querySelector('.nd-body');
        if (ndBody) {
            const firstFocusableElement = ndBody.querySelector('a, button, input, textarea');
            if (firstFocusableElement) {
                (firstFocusableElement as HTMLElement).focus();
            }
        }
    };

    return (
        <SessionController settings={settings} logout={logout}>
            <a href="#!" className="skip-to-content" onClick={handleSkipToContent}>
                Skip to main content
            </a>

            <main className="main" data-selected-menu={localStorage.getItem('nimbleSelectedMenu')}>
                <SiteMenu settings={settings} maintenance={maintenance} onLogout={onLogout} />

                <div className="nd-body">
                    {Children.map(children, (i) => i)}
                </div>

                <SiteFooter />

                {settings.chatbotEnabled && (
                    <>
                        <ChatBtn showChat={showChat} setShowChat={setShowChat} />
                        <ChatWidget showChat={showChat} setShowChat={setShowChat} />
                    </>
                )}
            </main>
        </SessionController>
    );
};

export default Layout;
